<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.word" placeholder="Full sentence" />
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
//     {
//       "word": "aeroplane",
//       "emptyNumbers": [2,4,6],
//       "lettersToShow": ["e","o","l"]
//     }
//   ]
export default {
  name: 'Type3b',
  data() {
    return {
      letterWords: [
        {
          word: '',
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords)).map(v => v.word)
      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
        word: '',
        emptyNumbers: '',
        lettersToShow: '',
      });
    },
  },
};
</script>
